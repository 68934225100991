/* Global error handling */
document.addEventListener("htmx:responseError",
    /**
     * Handles HTMX response errors globally by displaying the error message.
     * @param {CustomEvent<{xhr: XMLHttpRequest, error: string}>} e - The custom event containing error details.
     */
    (e) => {
    /** @type {HTMLElement} */
    const errorDiv =  document.querySelector("#error-msg");
    console.error(e.detail.error);
    errorDiv.innerHTML = e.detail.xhr.response;
    errorDiv.scrollIntoView();
});

/* Global no-content success handling */
document.body.addEventListener("successMessage",
    /**
     * Displays a success message using custom animations.
     * @param {CustomEvent<{value: string}>} evt - Custom event containing the success message.
     */
    function(evt){
    /** @type {HTMLElement} */
    const successDiv = document.querySelector("#success-msg");
    const divLeftOffset = successDiv.offsetLeft;
    const alertDiv = `<div class="uk-alert-success uk-animation-slide-top" style="position: absolute; left: ${divLeftOffset}px; right: 0; margin: 0 auto; text-align: center;" uk-alert><a href class="uk-alert-close" uk-close></a><div>${evt.detail.value}</div></div>`
    successDiv.insertAdjacentHTML('beforeend', alertDiv);
    setTimeout(() => {
        /** @type {HTMLElement} */
        const alert = document.querySelector("div[uk-alert]");
        alert.classList.remove("uk-animation-slide-top");
    }, 300)
    setTimeout(() => {
        /** @type {HTMLElement} */
        const alert = document.querySelector("div[uk-alert]");
        alert.classList.add("uk-animation-slide-top-medium", "uk-animation-reverse");
        setTimeout(() => {alert.remove()}, 500)
    }, 3000);
    htmx.process(successDiv);
});


document.body.addEventListener("errorMessage",
    /**
     * Displays an error message in an alert dialog.
     * @param {CustomEvent<{value: string}>} evt - Custom event containing the error message.
     */
    function (evt) {
    alert(evt.detail.value);
});


/* Global spinner/loader logic */
document.addEventListener("htmx:beforeRequest", () => {
    /** @type {HTMLElement} */
    const spinnerDiv = document.querySelector("#spinner");
    if(spinnerDiv) {
        spinnerDiv.classList.remove("uk-hidden");    
    }
    
})
document.addEventListener("htmx:afterRequest", () => {
    /** @type {HTMLElement} */
    const spinnerDiv = document.querySelector("#spinner");
    if(spinnerDiv) {
        spinnerDiv.classList.add("uk-hidden");    
    }
})

/**
 * Retrieves a CSRF token from the event target's ancestor.
 * @param {HTMLElement} eventTarget - The target of the event.
 * @returns {string|null} The CSRF token or null if not found.
 */
export function getCSRFToken(eventTarget) {
    /** @type {HTMLInputElement} */
    const tokenInput = eventTarget.querySelector('input[name="__RequestVerificationToken"]');
    return tokenInput ? tokenInput.value : null;
}


/**
 * Configures HTMX requests by adding a CSRF token to delete requests.
 * @param {CustomEvent} event - Custom event triggered by HTMX.
 */
document.addEventListener('htmx:configRequest', function (event) {
    if (event.detail.verb === 'delete') {
        const csrfToken = getCSRFToken(event.target);
        if (csrfToken) {
            // Add CSRF token to the request headers
            event.detail.headers['RequestVerificationToken'] = csrfToken;
        }
    }
});
