/** theTestimony/js/index.js
 * This is the master javascript file that entire site pulls on every page
 * This file mostly pull theme related styles
 */

/**
 * Imports the default uikit theme
 */
import "uikit/src/less/uikit.theme.less";

/**
 * Imports fontawesome
 */
import '@fortawesome/fontawesome-free/less/fontawesome.less';
import '@fortawesome/fontawesome-free/js/all';


/**
 * Imports UIKit javascript and makes it globally available via the window object
 */
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

/**
 * import custom theme and uikit theme overrides
 */
import '../less/main.less';

import htmx from 'htmx.org'
window.htmx = window.htmx || htmx;

UIkit.use(Icons);
window.UIkit = UIkit;

import './globalEvents'
